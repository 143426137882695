import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = { class: "card w-100" }
const _hoisted_5 = { class: "card-header" }
const _hoisted_6 = {
  class: "btn btn-secondary",
  type: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#multi-collapse-chart",
  "aria-expanded": "true",
  "aria-controls": "collapseExample"
}
const _hoisted_7 = {
  class: "collapse multi-collapse show",
  id: "multi-collapse-chart"
}
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "table-responsive" }
const _hoisted_10 = { class: "table" }
const _hoisted_11 = {
  scope: "col",
  class: "d-none d-md-table-cell"
}
const _hoisted_12 = { scope: "col" }
const _hoisted_13 = {
  scope: "col",
  class: "text-nowrap"
}
const _hoisted_14 = {
  scope: "col",
  class: "text-nowrap"
}
const _hoisted_15 = { scope: "col" }
const _hoisted_16 = {
  key: 0,
  scope: "col"
}
const _hoisted_17 = {
  scope: "row",
  class: "d-none d-md-table-cell"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 2 }
const _hoisted_21 = { class: "form-check form-switch" }
const _hoisted_22 = {
  class: "form-check-label",
  for: "flexSwitchCheckChecked"
}
const _hoisted_23 = { class: "col-md-6" }
const _hoisted_24 = { class: "card w-100" }
const _hoisted_25 = { class: "card-header" }
const _hoisted_26 = {
  class: "btn btn-secondary",
  type: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#multi-collapse-report",
  "aria-expanded": "true",
  "aria-controls": "collapseExample"
}
const _hoisted_27 = {
  class: "collapse multi-collapse show",
  id: "multi-collapse-report"
}
const _hoisted_28 = { class: "d-flex justify-content-around mt-2" }
const _hoisted_29 = {
  key: 0,
  class: "btn btn-danger",
  "data-bs-toggle": "modal",
  "data-bs-target": "#deleteReportModal"
}
const _hoisted_30 = { class: "card-body text-start" }
const _hoisted_31 = {
  key: 0,
  class: "text-center"
}
const _hoisted_32 = {
  key: 1,
  class: "text-center"
}
const _hoisted_33 = {
  key: 2,
  class: "text-center"
}
const _hoisted_34 = { class: "card-title text-center" }
const _hoisted_35 = {
  key: 3,
  class: "list-group"
}
const _hoisted_36 = { class: "list-group-item" }
const _hoisted_37 = { class: "list-group-item" }
const _hoisted_38 = {
  key: 4,
  class: "list-group"
}
const _hoisted_39 = { class: "list-group-item" }
const _hoisted_40 = { class: "list-group-item" }
const _hoisted_41 = {
  key: 5,
  class: "list-group"
}
const _hoisted_42 = { class: "list-group-item" }
const _hoisted_43 = { class: "list-group-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteReportModal = _resolveComponent("DeleteReportModal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("h5", _hoisted_5, [
              _createVNode("button", _hoisted_6, _toDisplayString($setup.t("diagnoses.chartinfo.title")), 1)
            ]),
            _createVNode("div", _hoisted_7, [
              _createVNode("div", _hoisted_8, [
                _createVNode("div", _hoisted_9, [
                  _createVNode("table", _hoisted_10, [
                    _createVNode("thead", null, [
                      _createVNode("tr", null, [
                        _createVNode("th", _hoisted_11, _toDisplayString($setup.t("diagnoses.chartinfo.id")), 1),
                        _createVNode("th", _hoisted_12, _toDisplayString($setup.t("diagnoses.chartinfo.time")), 1),
                        _createVNode("th", _hoisted_13, _toDisplayString($setup.t("diagnoses.chartinfo.hr")), 1),
                        _createVNode("th", _hoisted_14, _toDisplayString($setup.t("diagnoses.chartinfo.gain")), 1),
                        _createVNode("th", _hoisted_15, _toDisplayString($setup.t("diagnoses.chartinfo.device")), 1),
                        ($setup.role === 'admin')
                          ? (_openBlock(), _createBlock("th", _hoisted_16, "synthetic"))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createVNode("tbody", null, [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.diagnoses.data, (item) => {
                        return (_openBlock(), _createBlock("tr", {
                          key: item.diagnosis_id
                        }, [
                          _createVNode("th", _hoisted_17, _toDisplayString(item.diagnosis_id), 1),
                          ($setup.dbNum === 'v2')
                            ? (_openBlock(), _createBlock("td", _hoisted_18, _toDisplayString(item.create_date), 1))
                            : ($setup.dbNum === 'v1')
                              ? (_openBlock(), _createBlock("td", _hoisted_19, _toDisplayString(item.create_at), 1))
                              : _createCommentVNode("", true),
                          _createVNode("td", null, _toDisplayString(item.measures[0].heart_rate[29]), 1),
                          _createVNode("td", null, _toDisplayString(item.gain), 1),
                          _createVNode("td", null, _toDisplayString(item.device_id), 1),
                          ($setup.role === 'admin')
                            ? (_openBlock(), _createBlock("td", _hoisted_20, [
                                _createVNode("div", _hoisted_21, [
                                  _createVNode("input", {
                                    class: "form-check-input",
                                    type: "checkbox",
                                    role: "switch",
                                    id: "flexSwitchCheckChecked",
                                    checked: item.synthetic,
                                    onClick: ($event: any) => ($setup.switchSynthetic(item.diagnosis_id, item.synthetic))
                                  }, null, 8, ["checked", "onClick"]),
                                  _createVNode("label", _hoisted_22, _toDisplayString(item.synthetic), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createVNode("div", _hoisted_23, [
          _createVNode("div", _hoisted_24, [
            _createVNode("h5", _hoisted_25, [
              _createVNode("button", _hoisted_26, _toDisplayString($setup.t("diagnoses.reportinfo.title")), 1)
            ]),
            _createVNode("div", _hoisted_27, [
              _createVNode("div", _hoisted_28, [
                _createVNode("button", {
                  class: "btn btn-success",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.updateReport($setup.diagnoses.data[0].diagnosis_id))),
                  disabled: $setup.btnDisabled
                }, _toDisplayString($setup.t("diagnoses.reportinfo.updatereport")), 9, ["disabled"]),
                ($setup.role === 'admin')
                  ? (_openBlock(), _createBlock("button", _hoisted_29, _toDisplayString($setup.t("diagnoses.reportinfo.deletereport")), 1))
                  : _createCommentVNode("", true)
              ]),
              ($setup.reportData.data.length !== 0)
                ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList($setup.reportData.data, (item) => {
                    return (_openBlock(), _createBlock("div", _hoisted_30, [
                      ($setup.reportData.data[0].description[0].lang === 'zh_tw')
                        ? (_openBlock(), _createBlock("p", _hoisted_31, " 本次檢測不作為心臟疾病的專業臨床診斷依據 "))
                        : ($setup.reportData.data[0].description[0].lang === 'zh_cn')
                          ? (_openBlock(), _createBlock("p", _hoisted_32, " 本次检测不作为心脏疾病的专业临床诊断依据 "))
                          : ($setup.reportData.data[0].description[0].lang === 'en_us')
                            ? (_openBlock(), _createBlock("p", _hoisted_33, " This test is not intended to be used as a basis for professional clinical diagnosis of heart disease "))
                            : _createCommentVNode("", true),
                      _createVNode("h5", _hoisted_34, _toDisplayString($setup.t("diagnoses.reportinfo.result")) + _toDisplayString(item.type), 1),
                      ($setup.reportData.data[0].description[0].lang === 'zh_tw')
                        ? (_openBlock(), _createBlock("ul", _hoisted_35, [
                            _createVNode("li", _hoisted_36, " 描述：" + _toDisplayString(item.description[0].text_content), 1),
                            _createVNode("li", _hoisted_37, " 建議：" + _toDisplayString(item.suggestions[0].text_contents[0].text_content), 1)
                          ]))
                        : ($setup.reportData.data[0].description[0].lang === 'zh_cn')
                          ? (_openBlock(), _createBlock("ul", _hoisted_38, [
                              _createVNode("li", _hoisted_39, " 描述：" + _toDisplayString(item.description[0].text_content), 1),
                              _createVNode("li", _hoisted_40, " 建议：" + _toDisplayString(item.suggestions[0].text_contents[0].text_content), 1)
                            ]))
                          : ($setup.reportData.data[0].description[0].lang === 'en_us')
                            ? (_openBlock(), _createBlock("ul", _hoisted_41, [
                                _createVNode("li", _hoisted_42, " Description：" + _toDisplayString(item.description[0].text_content), 1),
                                _createVNode("li", _hoisted_43, " Suggestion：" + _toDisplayString(item.suggestions[0].text_contents[0].text_content), 1)
                              ]))
                            : _createCommentVNode("", true)
                    ]))
                  }), 256))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_DeleteReportModal)
  ], 64))
}