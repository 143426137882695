import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ChartInfo = _resolveComponent("ChartInfo")!
  const _component_TagInfo = _resolveComponent("TagInfo")!
  const _component_EvaluationInfo = _resolveComponent("EvaluationInfo")!
  const _component_SciChart = _resolveComponent("SciChart")!
  const _component_TagNoteModal = _resolveComponent("TagNoteModal")!
  const _component_ChangeTagNoteModal = _resolveComponent("ChangeTagNoteModal")!
  const _component_DeleteTagNoteModal = _resolveComponent("DeleteTagNoteModal")!
  const _component_DeleteEvaluationTagModal = _resolveComponent("DeleteEvaluationTagModal")!
  const _component_ModifyEvaluationTagModal = _resolveComponent("ModifyEvaluationTagModal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Loading, { active: $setup.isLoading }, null, 8, ["active"]),
    _createVNode(_component_ChartInfo),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_TagInfo),
        _createVNode(_component_EvaluationInfo)
      ])
    ]),
    _createVNode(_component_SciChart),
    _createVNode(_component_TagNoteModal),
    _createVNode(_component_ChangeTagNoteModal),
    _createVNode(_component_DeleteTagNoteModal),
    _createVNode(_component_DeleteEvaluationTagModal),
    _createVNode(_component_ModifyEvaluationTagModal)
  ], 64))
}