import { toDisplayString as _toDisplayString, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12 col-md-6"
}
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = {
  class: "collapse multi-collapse",
  id: "multi-collapse-tag"
}
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "form-check form-switch d-none d-xl-inline-block mt-2" }
const _hoisted_7 = {
  class: "form-check-label",
  for: "tag-mode"
}
const _hoisted_8 = { class: "table" }
const _hoisted_9 = { scope: "col" }
const _hoisted_10 = { scope: "col" }
const _hoisted_11 = { scope: "col" }
const _hoisted_12 = {
  scope: "col",
  class: "d-none d-xl-table-cell"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { key: 3 }
const _hoisted_17 = { key: 4 }
const _hoisted_18 = { key: 5 }
const _hoisted_19 = { key: 6 }
const _hoisted_20 = { key: 7 }
const _hoisted_21 = { key: 8 }
const _hoisted_22 = { class: "d-none d-xl-table-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.role === 'admin' || $setup.dbNum === 'v1')
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("h5", _hoisted_3, [
            _createVNode("button", {
              class: "btn btn-secondary",
              type: "button",
              "data-bs-toggle": "collapse",
              "data-bs-target": "#multi-collapse-tag",
              "aria-expanded": "true",
              "aria-controls": "collapseExample",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.isShowData($setup.dataSwitch.tag)))
            }, _toDisplayString($setup.t("diagnoses.taginfo.title")), 1)
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("div", _hoisted_6, [
                _withDirectives(_createVNode("input", {
                  class: "form-check-input",
                  type: "checkbox",
                  id: "tag-mode",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ($setup.isChecked = $event))
                }, null, 512), [
                  [_vModelCheckbox, $setup.isChecked]
                ]),
                _createVNode("label", _hoisted_7, _toDisplayString($setup.t("diagnoses.taginfo.tagmode")), 1)
              ]),
              _createVNode("table", _hoisted_8, [
                _createVNode("thead", null, [
                  _createVNode("tr", null, [
                    _createVNode("th", _hoisted_9, _toDisplayString($setup.t("diagnoses.taginfo.table.channel")), 1),
                    _createVNode("th", _hoisted_10, _toDisplayString($setup.t("diagnoses.taginfo.table.time")), 1),
                    _createVNode("th", _hoisted_11, _toDisplayString($setup.t("diagnoses.taginfo.table.remark")), 1),
                    _createVNode("th", _hoisted_12, _toDisplayString($setup.t("diagnoses.taginfo.table.operation")), 1)
                  ])
                ]),
                _createVNode("tbody", null, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.tagDataShow, (item, i) => {
                    return (_openBlock(), _createBlock("tr", { key: i }, [
                      (item.channel === '5')
                        ? (_openBlock(), _createBlock("th", _hoisted_13, "avF"))
                        : (item.channel === '4')
                          ? (_openBlock(), _createBlock("th", _hoisted_14, "aVL"))
                          : (item.channel === '3')
                            ? (_openBlock(), _createBlock("th", _hoisted_15, "aVR"))
                            : (item.channel === '2')
                              ? (_openBlock(), _createBlock("th", _hoisted_16, "LEAD 3"))
                              : (item.channel === '1')
                                ? (_openBlock(), _createBlock("th", _hoisted_17, "LEAD 2"))
                                : (item.channel === '0')
                                  ? (_openBlock(), _createBlock("th", _hoisted_18, "LEAD 1"))
                                  : (_openBlock(), _createBlock("th", _hoisted_19)),
                      (item.x1)
                        ? (_openBlock(), _createBlock("td", _hoisted_20, _toDisplayString((item.x1 / 250).toFixed(2)) + "s ~ " + _toDisplayString((item.x2 / 250).toFixed(2)) + "s ", 1))
                        : (_openBlock(), _createBlock("td", _hoisted_21)),
                      _createVNode("td", null, _toDisplayString(item.note), 1),
                      _createVNode("td", _hoisted_22, [
                        _createVNode("a", {
                          href: "#",
                          class: "btn btn-primary me-2",
                          "data-bs-toggle": "modal",
                          "data-bs-target": "#modifyModal",
                          onClick: ($event: any) => ($setup.modalData(i))
                        }, _toDisplayString($setup.t("diagnoses.taginfo.table.editbtn")), 9, ["onClick"]),
                        _createVNode("a", {
                          href: "#",
                          class: "btn btn-danger me-2",
                          "data-bs-toggle": "modal",
                          "data-bs-target": "#deleteModal",
                          onClick: ($event: any) => ($setup.modalData(i))
                        }, _toDisplayString($setup.t("diagnoses.taginfo.table.delbtn")), 9, ["onClick"])
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}